@use "sass:map";

.header-kids-with-textblock {
    height: 608px;
    position: relative;

    @include media('<medium') {
        height: 600px;
    }

    > .row {
        height: 100%;

        .columns {
            position: relative;

            @include media('<medium') {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    img.cover-image {
        object-fit: cover;
        width: 100%;
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .header-image-container {
        position: absolute;
        width: 50%;
        height: 100%;
        z-index: -1;
        max-width: 564px;
        margin-left: 88px;

        @include media('<medium') {
            max-height: 400px;
            width: 100%;
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 100%;
            overflow: hidden;
            padding: 0;
            position: relative;
        }

        img {
            position: absolute;

            @include media('<medium') {
                width: auto;
                height: 100%;
                position: relative;
                left: auto !important;
                top: auto !important;
            }
        }
    }

    .header-content {
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: end;
        padding-right: 88px;

        @include media('<1500px') {
            padding-right: 0;
        }

        @include media('<medium') {
            height: auto;
        }

        .text-block {
            background-color: $white;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            padding: 63px 91px 19px 91px;
            max-width: 869px;

            @include media('<large') {
                padding: 40px 40px 20px 40px;
            }

            h1 {
                font-size: rem-calc(40);
                margin-bottom: 0;

                @include media('<medium') {
                    font-size: rem-calc(30);
                }
            }

            p {
                font-size: rem-calc(22.5);
                margin-bottom: 0;

                @include media('<medium') {
                    font-size: rem-calc(18);
                }
            }
        }
    }

    &.with-search {
        display: flex;
        align-items: center;
    }
}