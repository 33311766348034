@use "sass:map";

.slider-with-bible-text {
    padding: 131px 0 135px 0;
    position: relative;

    @include media('<medium') {
        padding: 80px 0 80px 0;
    }

    img.background-image {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    h2 {
        color: $white;
        text-align: center;
        margin-bottom: 86px;
    }

    .glide-bible-texts {
        .glide__track,
        .glide__slide {
            height: auto;
        }

        .bible-text-block {
            background-color: $white;
            border-radius: 25px;
            padding: 50px 150px 30px 150px;
            text-align: center;
            width: 100%;
            transition: background-color .2s ease-in, color .2s ease-in;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include media('<1200px') {
                padding: 50px;
            }

            @include media('<medium') {
                padding: 30px;
            }

            p {
                font-family: var(--root-heading-font-family);
                font-size: rem-calc(22.5);
                color: $purple;
                line-height: 1.3333;
                margin-bottom: 49px;
                transition: color .2s ease-in;

                @include media('<medium') {
                    font-size: rem-calc(18);
                    margin-bottom: 20px;
                }
            }
        }

        .glide__slide--active {
            .bible-text-block {
                background-color: $blue;
                color: $white;

                p {
                    color: $white;
                }
            }
        }
    }

    .glide-bible-texts {
        &:hover {
            .glide__arrow {
                opacity: 1;
            }
        }
    }

    .glide__arrow {
        top: calc(60% - 4px);
        box-shadow: none;
        border: 0;

        @include media('>large') {
            opacity: 0;
        }

        &--left {
            left: 30px;
            transition: margin-left .2s ease-in;

            &:hover {
                margin-left: -10px;
            }
        }

        &--right {
            right: 30px;
            transition: margin-right .2s ease-in;

            &:hover {
                margin-right: -10px;
            }
        }
    }
}