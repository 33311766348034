@use "sass:map";
$top-footer-padding: (desktop: 80px 0, tablet: 40px 0, mobile: 20px 0);
$top-footer-background: $white;
$h2-font-size: (desktop: rem-calc(19), tablet: rem-calc(18), mobile: rem-calc(16));
$h2-color: #50AB41;

.footer {
    background-color: $top-footer-background;
    padding: 12px 0 30px 0;

    .row {
        padding: 67px 0 0 0;
        border-top: 2px solid $light-blue;
    }

    .columns {
        @include media('>1680px') {
            margin-left: -15px;
        }
    }

    &__logo {
        margin-bottom: 59px;
        display: block;

        + p {
            line-height: 1.39;
            padding-right: 10px;
        }
    }

    &__menu {
        margin-top: 124px;
        padding-left: 75px;

        @include media('<large') {
            padding-left: 30px;
        }

        @include media('<medium') {
            margin-top: 10px;
            padding-left: 0;
        }

        nav {
            ul {
                list-style: none;
                margin-left: 0;
                column-count: 3;

                @include media('<1280px') {
                    column-count: 2;
                }

                @include media('<large') {
                    column-count: 3;
                }

                @include media('<medium') {
                    column-count: 2;
                }

                li {
                    a {
                        font-weight: bold;
                        line-height: 2.83;

                        &:hover {
                            color: $purple;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &__socials {
        margin-top: 52px;
        padding-left: 50px;

        @include media('<large') {
            padding-left: 0;
            margin-top: 30px;
        }

        .newsletter-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                color: $blue;
                font-size: rem-calc(22.5);
                display: block;
                margin-bottom: 13px;
            }
        }

        .social-media {
            margin-top: 84px;
            display: flex;

            @include media('<large') {
                margin-top: 40px;
            }

            span {
                margin-right: 7px;
            }

            a {
                font-size: rem-calc(24);

                &:hover {
                    color: $blue;
                }
            }

            &-icons {
                display: flex;
                margin-top: -2px;
                gap: 14px;
            }
        }
    }

    &__bottom {
        .row {
            padding: 16px 0;
            border-top: 2px solid $light-blue;
        }
        
        .columns {
            @include media('>1680px') {
                margin-left: -15px;
            }
        }

        nav {
            ul {
                display: flex;
                gap: 51px;
                list-style: none;
                margin-left: 0;
                margin-bottom: 0;
                flex-wrap: wrap;

                //@include breakpoint(small down) {
                @include media('<medium') {
                    flex-direction: column;
                    gap: 10px;
                }

                li {
                    a {
                        color: $blue;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
