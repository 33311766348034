$fa-font-path: '../fonts';

$root: (
        border-radius: 10px,
        font-family: 'Open Sans, sans-serif',
        heading-font-family: 'Amaranth, sans-serif',
        heading-font-family-kids: 'Gooddog New, sans-serif',
        font-size: rem-calc(18),
        box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4),
);

/**
Headings
 */
$heading-color: #000;
$h1-font-size: rem-calc(62);
$h2-font-size: rem-calc(40);
$h3-font-size: rem-calc(34);
$h4-font-size: rem-calc(26);
$h5-font-size: rem-calc(22);
$h6-font-size: rem-calc(18);

/**
Suptitle
 */
$suptitle-font-size: rem-calc(17);
$suptitle-font-weight: 700;
$suptitle-color: #50AB41;
$suptitle-line-width: 60px;

/**
Colors
 */
$black: #000;
$white: #fff;
$blue:                  #64C5EA;
$light-blue:            #D8F1FA;
$purple:                #923B86;
$light-purple:          #E4CEE1;
$red:                   #E73C4A;
$light-red:             #ED6D77;