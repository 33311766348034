@use "sass:map";

.share-page {
    padding: 54px 0;

    @include media('<medium') {
        padding: 40px 0;
    }

    .content {
        padding-left: 88px;
        color: rgba($purple, 50%);
        display: flex;

        @include media('<large') {
            padding-left: 30px;
        }

        @include media('<medium') {
            padding-left: 0;
        }

        span {
            margin-right: 15px;
        }

        .social-icons {
            display: flex;
            align-items: center;
            gap: 14px;
            a {
                transition: transform .1s ease-in;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }
}