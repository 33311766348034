@use "sass:map";
/**
Add the global SCSS/CSS here that should be available throughout the website
 */

:root {
    @each $name, $value in $root {
        --root-#{$name}: #{$value};
    }
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: var(--root-font-family);
    font-weight: 400;
    font-size: 18px;
    color: $purple;


    &::before {
        content: "";
        background: url("/assets/templates/images/svg/lines-bg.svg");
        height: 459px;
        width: 141px;
        position: absolute;
        top: 243px;
        left: -7px;
        z-index: 99;

        @include media('<medium') {
            display: none;
        }
    }

    main {
        flex-grow: 1;
    }
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
    font-family: var(--root-heading-font-family);
    font-weight: 700;
    color: $purple;
}

p, a {
    font-family: var(--root-font-family);
    font-weight: 400;
    color: $purple;
}

button {
    cursor: pointer;
}

$content-page-margin-bottom: (desktop: 140px, tablet: 70px, mobile: 45px);

main.content-page {
    section:last-of-type {
        margin-bottom: map.get($content-page-margin-bottom, desktop);

        //@include breakpoint(medium down) {
        @include media('<large') {
            margin-bottom: map.get($content-page-margin-bottom, tablet);
        }

        //@include breakpoint(small down) {
        @include media('<medium') {
            margin-bottom: map.get($content-page-margin-bottom, mobile);
        }
    }
}
