@use "sass:map";
$header-background-color: #fff;
$header-padding: 30px 0;
$header-logo-max-width: 100px;

/*
 * Styling for the header
 */
.header {
    //position: fixed;
    width: 100%;
    z-index: 30;
    
    @include media('<large') {
        position: fixed;

        + main {
             padding-top: 80px;
        }
    }

    &.menu-open {
        .header__nav {
            right: 0;
        }

        .header__menutrigger {
            .icon {
                &::before {
                    transform: translateY(50%) rotate(45deg);
                    top: 0;
                }

                &::after {
                    transform: translateY(-50%) rotate(-45deg);
                    top: 2px;
                }
            }

            .base-line {
                opacity: 0;
            }
        }
    }

    &.lang-menu-open {
        .header-top__wrapper {
            right: 0;
        }
    }

    &-top__wrapper {
        height: 135px;
        background-color: $white;
        transition: height .2s ease-in;
        position: fixed;
        width: calc(100vw - ((100vw - 1610px) / 2) - 89px - 376px );
        right: 0;
        border-radius: 0 0 0 50px;

        > .row {
            height: 100%;

            @include media('<1920px') {
                //padding-right: 200px;
            }

            @include media('<large') {
                padding-right: 0;
            }
        }

        .header-inner {
            display: flex;
            height: 100%;
            justify-content: flex-end;
            padding-right: 220px;

            @include media('<1500px') {
                //padding-right: 0;
            }

            @include media('<1200px') {
                justify-content: flex-end;
            }

            @include media('<large') {
                flex-direction: column;
                justify-content: center;
                padding-right: 0;
            }

            .switch-container {
                display: flex;
                font-size: rem-calc(16);
                align-items: center;

                &:first-child {
                    margin-right: 30px;
                }

                span {
                    color: $blue;
                    margin-right: 10px;

                    @include media('<1200px') {
                        margin-bottom: 5px;
                    }
                }

                @include media('<1200px') {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    margin-bottom: 18px;
                }

                .switch-buttons {
                    display: flex;

                    .switch-btn {
                        background-color: $light-blue;
                        color: $blue;
                        padding: 10px 20px;
                        transition: background-color .2s ease-in, color .2s ease-in;

                        &:first-child {
                            border-top-left-radius: 25px;
                            border-bottom-left-radius: 25px;
                        }

                        &:last-child {
                            border-top-right-radius: 25px;
                            border-bottom-right-radius: 25px;
                        }

                        &.selected {
                            background-color: $blue;
                            color: $white;
                            font-weight: bold;
                        }

                        &:hover {
                            background-color: $blue;
                            color: $white;
                        }
                    }
                }
            }
        }

       .show-keywords {
           position: absolute;
           color: $purple;
           top: 50%;
           right: 0;
           transform: translateY(-50%);
           border: 1px solid purple;
           border-radius: 30px 0 0 30px;
           padding: 11px 25px 11px 32px;
           border-right: 0;
           font-size: 1.40625rem;
           font-family: var(--root-heading-font-family);
           transition: background-color .2s ease-in, color .2s ease-in;

           &:hover {
               background-color: $purple;
               color: $white;
           }

           @include media('<large') {
               display: none;
           }
       }

        @include media('<large') {
            position: fixed;
            right: -100vw;
            top: 80px;
            width: 100vw;
            background-color: #fff;
            height: calc(50vh - 80px);
            transition: right .25s ease-in;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            box-shadow: 0 7px 14px -7px grey;
        }
    }

    &__wrapper {
        height: 135px;
        background-color: $purple;
        transition: height .2s ease-in;

        margin-top: 135px;

        > .row {
            height: 100%;
        }

        .header-inner {
            color: $white;
            height: 135px; /* 100% */
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding-right: 88px;
            transition: height .2s ease-in;

            @include media('<1500px') {
                padding-right: 0;
            }
        }

        @include media('<large') {
            height: 80px;
            margin-top: 0;
        }
    }

    &__logo {
        margin: auto 0;
        font-weight: 700;
        font-size: 22px;
        z-index: 11;
        background-color: $white;
        padding: 47px 62px;
        height: 203px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        margin-left: 89px;
        transition: padding .2s ease-in, height .2s ease-in;

        @include media('<1500px') {
            padding: 30px;
            height: 180px;
            margin-left: 0;
        }

        @include media('<large') {
            height: auto;
            margin-top: 0;
        }

        @include media('<medium') {
            font-size: 20px;
            box-shadow: 0 15px 10px -15px #ccc;
        }

        @include media('<400px') {
            padding: 25px 10px 25px;
        }

        img {
            @include media('<large') {
                width: 120px;
            }
        }
    }

    &__nav {
        @include media('<large') {
            position: fixed;
            right: -100vw;
            top: 80px;
            width: 100vw;
            background-color: $purple;
            height: 75vh; //calc(75vh - 80px);
            transition: right .250s ease-in;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            box-shadow: 0 7px 14px -7px grey;
        }

        ul {
            display: flex;
            gap: 60px;
            align-items: center;

            @include media('<1500px') {
                gap: 40px;
            }

            @include media('<large') {
                margin-right: 0;
                margin-top: 0;
                gap: 20px;
                flex-direction: column;
            }

            li {
                display: inline;
                margin: 0;

                @include media('<large') {
                    display: block;
                    text-align: center;
                }

                a {
                    font-size: rem-calc(22.5);
                    font-weight: 500;
                    padding: 10px 0;
                    color: $white;
                    font-family: var(--root-heading-font-family);

                    @include media('<1200px') {
                        font-size: rem-calc(18);
                    }
                }

                &:hover {
                    a {
                        text-decoration: underline;
                    }
                }

                &:last-child {
                    @include media('>large') {
                        display: none;
                    }

                    .show-keywords {
                        background-color: #AE6CA5;
                        padding: 16.5px 33px;
                        border-radius: 50px;
                        color: $white;
                    }
                }

                .search-btn {
                    background: url("/assets/templates/images/svg/search.svg");
                    height: 28px;
                    width: 28px;
                    background-size: 100%;

                    @include media('<large') {
                        //background: url("/assets/templates/images/svg/search-purple.svg");
                    }
                }
            }
        }
    }

    &__menutrigger {
        display: none;
        border-radius: 50px;
        background-color: $white;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 999;
        position: absolute;
        top: 15px;
        right: 0;

        .icon {
            display: block;
            height: 2px;
            width: 50%;
            padding-right: 15%;
            margin: 0 auto;
            position: relative;
            transition: padding .15s ease-out, height .15s ease-out;

            &::before,
            &::after  {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                background: $purple;
                transition: transform .15s ease-out;
            }

            &::before {
                top: -6px;
            }

            &::after {
                top: 6px;
            }

            .base-line {
                display: block;
                width: 100%;
                height: 100%;
                background: $purple;
                transition: background-color .15s ease-out;
            }
        }

        &:hover {
            .icon {
                padding-left: 15%;
                padding-right: 0;
            }
        }

        @include media('<large') {
            display: flex;
        }
    }

    &__lang-toggle {
        display: none;
        border-radius: 50px;
        background-color: $white;
        color: $purple;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 999;
        position: absolute;
        top: 15px;
        right: 70px;

        @include media('<large') {
            display: flex;
        }
    }



    /*+ main {
        padding-top: 270px;

        @include media('<large') {
            padding-top: 80px;
        }
    }*/

    /*&.scrolled {
        .header-top__wrapper {
            height: 80px;
        }

        .header__wrapper {
            height: 100px;

            .header-inner {
                height: 100px;
            }
        }

        .header__logo {
            padding: 27px 62px;
            height: 170px;
            box-shadow: 0 15px 10px -15px #ccc;
        }

        + main {
            padding-top: 180px;
        }
    }*/

    &.scrolled {
        .header-top__wrapper {
            box-shadow: 0 5px 10px rgba(0,0,0,.16);
            z-index: 1;
        }
    }
}
