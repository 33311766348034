@use "sass:map";

.slider-with-keywords {
    padding: 0 0 71px 0;

    > .row {
        border-top: 2px solid $light-blue;
        padding-top: 130px;
    }

    h2 {
        margin-bottom: 63px;
    }

    .related-keywords {
        &__item {
            a {
                &:hover {
                    color: $purple;

                    .cover-image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .cover-image {
                height: 387px;
                overflow: hidden;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include media('<medium') {
                    height: 225px;
                }

                img {
                    object-fit: cover;
                    //width: 100%;
                    //height: inherit;
                    border-radius: 25px;
                    transition: transform .2s ease-in;
                    width: 100%;
                    height: 100%;
                }
            }

            .content {
                padding: 33px 19px;
                
                @include media('<medium') {
                    padding: 10px 19px;
                }

                .title {
                    font-size: rem-calc(22.5);
                    margin-bottom: 14px;
                    font-weight: bold;
                    display: block;
                }

                p {
                    margin-bottom: 0;
                    padding-right: 38px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    @include media('<1500px') {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}