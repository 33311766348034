@use "sass:map";

.search-bar {
    max-width: 1082px;
    margin: 0 auto;
    width: 100%;

    @include media('<large') {
        margin: 20px;
    }

    input {
        border-radius: 40px;
        height: 76px;
        border: 1px solid $purple;
        font-size: rem-calc(22.5);
        padding: 10px 54px;
        font-family: var(--root-heading-font-family);
        background: $white url("/assets/templates/images/svg/search-purple.svg") no-repeat center;
        background-position-x: calc(100% - 54px);
        color: $purple;

        @include media('<medium') {
            height: 60px;
            padding: 10px 25px;
            background-position-x: calc(100% - 25px);
        }

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $purple;
            opacity: 1; /* Firefox */
            font-weight: bold;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $purple;
            font-weight: bold;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: $purple;
            font-weight: bold;
        }
    }
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

section.search {
    padding: 64px 0;
}