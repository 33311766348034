@use "sass:map";

.payment-popup {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: $purple;
    z-index: 31;
    transition: opacity .2s ease-in, visibility .2s ease-in;
    overflow-x: scroll;

    &.show {
        opacity: 1;
        visibility: visible;
    }

    &__content {
        color: $white;
        /* padding-left: 309px;
        padding-right: 57px; */
        margin-top: 251px;
        max-height: calc(100vh - 250px);
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        gap: 86px;

        @include media('<1600px') {
            gap: 50px;
        }

        @include media('<1280px') {
            flex-direction: column;
            margin-top: 40px;
            padding-left: 237px;
        }

        h2 {
            margin-bottom: 16px;
        }

        p {
            font-size: rem-calc(22.5);
            margin-bottom: 5px;
        }

        .payment-option {
            max-width: 601px;
            border-radius: 25px;
            border: 1px solid #fff;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            overflow: hidden;
            background-color: $white;

            @include media('<1280px') {
                overflow: visible;
            }

            .title {
                background-color: $blue;
                color: $white;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                padding: 14px 39px;
                font-weight: bold;
            }

            .content {
                color: $purple;
                padding: 28px 48px 49px 39px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100% - 54px);

                @include media('<1280px') {
                    height: 100%;
                }
            }
        }

        .button-container {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 30px;
            gap: 20px;

            .btn {
                margin-left: auto;

                @include media('<1280px') {
                    margin-left: initial;
                }
            }

            .payment-input {
                padding: 56px 20px;
                border: 1px solid $light-purple;
                border-radius: 4px;
                width: 265px;
                margin-bottom: 0;
                font-family: var(--root-heading-font-family);
                font-size: 40px;
                text-align: center;
                color: $purple;

                @include media('<medium') {
                    width: 100%;
                }

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $light-purple;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $light-purple;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: $light-purple;
                }
            }

            @include media('<1280px') {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        @include media('<medium') {
            padding-left: 40px;
            padding-right: 40px;
            margin-top: 50px;
        }
    }

    &__close {
        color: $white;
        font-family: var(--root-heading-font-family);
        border-radius: 50%;
        border: 1px solid #fff;
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: rem-calc(22.5);
        transition: background-color .2s ease-in, color .2s ease-in;
        margin-top: 50px;

        @include media('<medium') {
            margin-top: 30px;
        }

        &:hover {
            background-color: $white;
            color: $purple;
        }

        span {
            margin-top: -2px;
        }
    }
}

.open-payment-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 81px;
    height: 82px;
    background: url("/assets/templates/images/svg/payment-icon.svg") no-repeat;
    background-size: 100%;
    transition: transform .2s ease-in;

    &:hover {
        transform: scale(1.1);
    }
}