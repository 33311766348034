@use "sass:map";

.newsletter {
    padding: 129px 0 54px 0;

    @include media('<medium') {
        padding: 50px 0;
    }

    h2 {
        color: $blue;
        font-size: rem-calc(40);
        margin-bottom: 38px;
        line-height: 1.5;
    
        @include media('<medium') {
            line-height: 1.25;
        }
    }

    .content {
        padding: 0 29px;
    
        @include media('<medium') {
            padding: 0;
        }
    }

    form {
        margin-top: 37px;

        .form-group {
            margin-bottom: 20px;
        }

        .form-pagination {
            margin-top: 98px;
    
            @include media('<medium') {
                margin-top: 40px;
            }
        }
    }
}