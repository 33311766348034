@use "sass:map";

.tabs-container {
    position: relative;

    &::before {
        content: "";
        background: url("/assets/templates/images/svg/lines-blue-bg-left.svg");
        height: 449px;
        width: 141px;
        position: absolute;
        top: 112px;
        left: -7px;
        z-index: -1;

        @include media('<large') {
            //display: none;
        }
    }

    > .row {
        border-bottom: 2px solid $light-blue;
    }

    .content {
        background-color: $light-blue;
        margin: 0 88px;
        padding: 45px 146px 0 146px;

        @include media('<1500px') {
            padding: 45px 50px 0 50px;
            margin: 50px 50px 0 50px;
        }

        @include media('<large') {
            padding: 30px 30px 0 30px;
            margin: 30px;
        }

        @include media('<medium') {
            padding: 20px 20px 0 20px;
            margin: 0;
        }

        .tabs {
            border: 0;
            background: transparent;
            display: flex;

            @include media('<medium') {
                flex-direction: column;
            }

            .tabs-title {
                padding: 0;

                @include media('>large') {
                    flex: 1;
                }

                a {
                    background-color: transparent;
                    color: $blue;
                    font-family: var(--root-heading-font-family);
                    font-size: rem-calc(22.5);
                    padding: rem-calc(25) rem-calc(31);
                    transition: background-color .2s ease-in;

                    @include media('<large') {
                        font-size: rem-calc(18);
                        padding: rem-calc(20) rem-calc(30);
                    }

                    &:hover {
                        background-color: rgba($white, 50%);
                    }
                }

                &.is-active {
                    a {
                        background-color: $white;
                    }
                }
            }
        }

        .tabs-content {
            border: 0;

            .tabs-panel {
                padding: 45px 30px;

                @include media('<medium') {
                    padding: 30px 20px;
                }
            }

            .tab-bijbelrooster {
                .day-item {
                    border: 1px solid $blue;
                    border-radius: 5px;
                    padding: 40px 40px 26px 40px;
                    margin-bottom: 30px;

                    @include media('<large') {
                        padding: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &__header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 31px;

                        @include media('<1280px') {
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                        }

                        .left {
                            display: flex;
                            align-items: center;

                            @include media('<medium') {
                                flex-direction: column;
                                align-items: flex-start;
                            }

                            .day {
                                color: $blue;
                                font-weight: bold;
                                width: 66px;
                            }

                            .title {
                                font-size: rem-calc(22.5);
                                color: $purple;
                                font-family: var(--root-heading-font-family);
                                line-height: 1.3333;

                                @include media('<medium') {
                                    font-size: rem-calc(18);
                                }
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;
                            color: $blue;

                            @include media('<medium') {
                                align-items: flex-start;
                                flex-direction: column;
                                gap: 5px;
                            }

                            button {
                                color: $blue;
                                line-height: 1.6667;

                                &:hover {
                                    text-decoration: underline;
                                }

                                &.active {
                                    color: $purple;
                                        font-weight: bold;
                                }
                            }

                            .divider {
                                margin: 0 4px;
                                
                                @include media('<medium') {
                                    display: none;
                                }
                            }
                        }
                    }

                    &__content {
                        p {
                            line-height: 1.66667;
                            color: $black;
                        }
                    }

                    &__read-text,
                    &__listen-text {
                        background-color: rgba($purple, 5%);
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height .25s ease-in, margin .2s ease-in;

                        &-inner {
                            padding: 46px 40px;

                            @include media('<medium') {
                                padding: 20px 20px;
                            }

                            p {
                                line-height: 1.6667;
                                margin-bottom: 30px;
                                color: $black;

                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        &.show {
                            max-height: 100%;
                            margin-bottom: 43px;
                        }
                    }

                    &__listen-text {
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height .25s ease-in, margin .2s ease-in;

                        &-inner {
                            padding: 0;
                        }

                        &.show {
                            max-height: 100%;
                            margin-bottom: 43px;
                        }
                    }
                }
            }

            .tab-leestips {
                .book-grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 40px 0;

                    &__item {
                        display: flex;
                        width: 50%;

                        @include media('<large') {
                            width: 100%;
                        }

                        .left {
                            padding: 0;
                            min-width: 180px;
                        }

                        .right {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            color: $purple;
                            padding: 82px 22px 42px 22px;

                            @include media('<1200px') {
                                padding: 20px 20px 20px 10px;
                            }

                            .meta {
                                display: flex;
                                flex-direction: column;
                            }

                            .title {
                                font-family: var(--root-heading-font-family);
                                font-size: rem-calc(22.5);
                                line-height: 1.3333;
                            }

                            .author {
                                line-height: 1.6667;
                            }

                            .btn {
                                font-family: var(--root-font-family);
                                font-size: rem-calc(18);
                                font-weight: bold;
                                padding: 8px 16px;

                                @include media('<1200px') {
                                    font-size: rem-calc(16);
                                }
                            }
                        }
                    }
                }
            }

            .tab-kijk-luister {
                .video-grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 22px 32px;

                    &__item {
                        display: flex;
                        flex-direction: column;
                        width: calc(50% - 16px);

                        @include media('<large') {
                            width: 100%;
                        }

                        .video {
                            img:first-of-type {
                                width: 100%;
                            }
                        }

                        .meta {
                            display: flex;
                            flex-direction: column;
                            color: $purple;
                            padding: 20px 19px;
                        }

                        .title {
                            line-height: 1.6667;
                            font-weight: bold;
                        }

                        .author {
                            line-height: 1.6667;
                        }
                    }
                }
            }

            .tab-verwerking {
                .text-grid {
                    &__item {
                        color: $black;
                        padding: 41px 40px 0 40px;
                        margin-bottom: 18px;

                        @include media('<medium') {
                            padding: 0 0 10px 0;
                        }

                        p {
                            line-height: 1.6667;
                                    color: $black;
                        }

                        .title {
                            font-family: var(--root-heading-font-family);
                            font-size: rem-calc(22.5);
                            line-height: 1.3333;
                        }

                        .indented-text {
                            color: #7C7C7C;
                            padding-left: 24px;
                            margin-top: 33px;
                            margin-bottom: 32px;

                            p {
                                color: #7C7C7C;
                                line-height: 1.6667;
                            }

                            ul {
                                list-style-type: none;
                                margin-left: 0;

                                > li {
                                    //text-indent: -5px;
                                    line-height: 1.6667;

                                    &::before {
                                        content: "-";
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .tab-zing {
                .psalm-item {
                    border: 1px solid $blue;
                    border-radius: 5px;
                    margin-bottom: 30px;

                    @include media('<large') {
                        padding: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &__header {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        padding: 38px 40px 0 40px;

                        @include media('<1280px') {
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            padding: 21px;
                        }

                        .left {
                            display: flex;
                            flex-direction: column;
                        }

                        .psalm {
                            color: $red;
                            font-family: var(--root-heading-font-family);
                            font-weight: bold;
                            font-size: rem-calc(22.5);
                        }

                        .link {
                            color: rgba(#7C7C7C, 50%);
                        }
                    }

                    .tab-content {
                        padding: 20px 21px;
                        display: flex;
                        gap: 22px;
                        flex-wrap: wrap;

                        .verse-button {
                            background-color: $red;
                            color: $white;
                            height: 63px;
                            width: calc(33.3333% - 15px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: background-color .2s ease-in;

                            @include media('<medium') {
                                width: calc(50% - 15px);
                                height: 50px;
                            }

                            &:hover {
                                background-color: $blue;
                            }

                            > div {
                                display: flex;

                                img {
                                    width: 25px;
                                    height: 25px;
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .tab-verwerking-kids {
                &.is-active {
                    display: flex;
                    justify-content: space-between;
                }

                @include media('<large') {
                    flex-direction: column;
                }

                .text-grid {
                    width: 75%;
                    padding: 0 51px 0 41px;
                    margin-top: 43px;

                    @include media('<large') {
                        width: 100%;
                    }

                    @include media('<medium') {
                        padding: 0;
                    }

                    &__item {
                        margin-bottom: 34px;

                        &-header {
                            margin-bottom: 2px;
                            .tag {
                                color: $blue;
                                font-weight: bold;
                                line-height: 1;
                            }

                            .title {
                                color: $red;
                                font-family: var(--root-heading-font-family);
                                font-size: rem-calc(22.5);
                                line-height: 1.3333;
                                line-height: 1;
                            }
                        }

                        .text {
                            p, li {
                                color: $black;
                                margin-bottom: 1px;
                            }

                            ul {
                                list-style-type: none;
                                margin-left: 0;

                                > li {
                                    //text-indent: -5px;
                                    line-height: 1.6667;

                                    &::before {
                                        content: "-";
                                        margin-right: 5px;
                                    }
                                }
                            }

                            .indented-text {
                                color: #7C7C7C;
                                padding-left: 24px;
                                margin-top: 33px;
                                margin-bottom: 32px;

                                p {
                                    color: #7C7C7C;
                                    line-height: 1.6667;
                                }

                                ul {
                                    > li {
                                        color: #7C7C7C;
                                    }
                                }
                            }
                        }
                    }
                }

                .downloads {
                    width: calc(25% - 15px);
                    border-left: 2px solid $light-blue;
                    padding: 0 12px 0 44px;
                    margin-top: 36px;

                    @include media('<large') {
                        width: 100%;
                    }

                    h3 {
                        font-family: var(--root-heading-font-family-kids);
                        color: #7C7C7C;
                        font-size: rem-calc(25);
                        margin-bottom: 4px;
                    }

                    &-item {
                        margin-bottom: 20px;

                        @include media('<large') {
                            width: calc(33% - 12px);
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        @include media('<medium') {    
                            width: calc(50% - 10px);
                        }

                        @include media('<400px') {
                            width: 100%;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        img {
                            margin-bottom: 20px;
                        }

                        .title {
                            font-family: var(--root-heading-font-family);
                            color: $blue;
                            font-size: rem-calc(18);
                            display: block;
                        }

                        .download-type {
                            font-size: rem-calc(15);
                        }

                        .btn {
                            padding: 7px 17px;
                            margin-top: 19px;
                            font-size: 18px;
                            font-family: var(--root-font-family);
                            font-weight: 700;
                        }
                    }

                    &-wrapper {
                        @include media('<large') {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                        }
                    }
                }
            }
        }
    }
}