@use "sass:map";

.keywords-popup {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: $purple;
    z-index: 31;
    transition: opacity .2s ease-in, visibility .2s ease-in;
    //
    //&::before {
    //    content: "";
    //    background: url("/assets/templates/images/svg/lines-bg.svg");
    //    height: 459px;
    //    width: 141px;
    //    position: absolute;
    //    top: 243px;
    //    left: -7px;
    //
    //    @include media('<medium') {
    //        display: none;
    //    }
    //}

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-left: 237px;
        padding-right: 57px;

        @include media('<medium') {
            padding-left: 40px;
            padding-right: 40px;
        }

        .header__logo {
            margin: auto 0;
            font-weight: 700;
            font-size: 22px;
            z-index: 11;
            background-color: $white;
            padding: 47px 62px;
            height: 203px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            display: inline-block;

            @include media('<1500px') {
                padding: 30px;
                height: 180px;
                margin-left: 0;
            }

            @include media('<large') {
                height: auto;
            }

            @include media('<medium') {
                font-size: 20px;
            }

            img {
                @include media('<large') {
                    width: 120px;
                }
            }
        }
    }

    &__content {
        color: $white;
        padding-left: 273px;
        padding-right: 0;
        margin-top: 0; //142px;
        max-height: calc(100vh - 250px);
        overflow-y: scroll;

        @include media('<medium') {
            padding-left: 40px;
            padding-right: 40px;
            margin-top: 50px;
        }

        .keywords-table {
            column-count: 3;

            @include media('<1280px') {
                column-count: 2;
            }

            @include media('<medium') {
                column-count: 1;
            }

            .keyword-wrapper {
                display: flex;

                &:first-child {
                    margin-top: 178px;

                    @include media('<medium') {
                        margin-top: 0;
                    }
                }

                .keyword-letter {
                    font-weight: bold;
                    width: 36px;
                    font-size: rem-calc(22.5);

                    @include media('<medium') {
                        font-size: rem-calc(18);
                    }
                }

                .keywords {
                    a {
                        color: $white;
                        display: block;
                        margin-bottom: 11px;
                        font-size: rem-calc(22.5);

                        @include media('<medium') {
                            font-size: rem-calc(18);
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &__close {
        color: $white;
        font-family: var(--root-heading-font-family);
        border-radius: 50%;
        border: 1px solid #fff;
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: rem-calc(22.5);
        transition: background-color .2s ease-in, color .2s ease-in;
        margin-top: 50px;

        @include media('<medium') {
            margin-top: 30px;
        }

        &:hover {
            background-color: $white;
            color: $purple;
        }

        span {
            margin-top: -2px;
        }
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }
}