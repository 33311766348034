@use "sass:map";

.header-with-video {
    height: 810px;
    position: relative;
    
    &.disable-video {
        video {
            display: none;
        }
        
        img {
            display: block;
        }

        .header-content {
            flex-direction: column;
            justify-content: space-between;

            h1 {
                display: block;
            }
        }
    }

    @include media('<medium') {
        height: 530px;
    }

    > .row {
        height: 100%;
    }

    img {
        display: none;
        object-fit: cover;
        width: 100%;
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    video {
        object-fit: cover;
        width: 100%;
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .header-content {
        height: 100%;
        padding-bottom: 144px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        h1 {
            display: none;
            color: $white;
            font-family: var(--root-heading-font-family-kids);
            font-size: rem-calc(90);
            max-width: 480px;
            margin-left: 150px;
            margin-top: 237px;
            line-height: 1;

            @include media('<medium') {
                font-size: rem-calc(60);
                margin-left: 0;
                margin-top: 100px;
                max-width: 70%;
            }
        }
    }
}