@use "sass:map";

.pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 95px;

    a {
        color: black;
        float: left;
        padding: 8px 10px;
        text-decoration: none;
        color: $blue;
        transition: transform .2s ease-in;

        &:first-child {
            margin-right: 9px;
        }

        &:last-child {
            margin-left: 9px;
        }

        &.disabled {
            opacity: .5;
        }

        &.active {
            font-weight: bold;
        }

        &:hover:not(.active) {
            transform: scale(1.2);
        }
    }

    span.disabled {
        opacity: 0.5;

        &.next {
            margin-left: 19px;
        }

        &.prev {
            margin-right: 19px;
        }
    }
}