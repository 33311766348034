@use "sass:map";

.text-block {
    padding: 132px 0 119px 0;

    @include media('<1280px') {
        padding: 50px 0;
    }

    .content {
        padding: 0 98px;

        @include media('<1280px') {
            padding: 0;
        }

        h2 {
            margin-bottom: 40px;
        }

        p {
            color: $black;
            
            &:first-of-type {
                font-weight: bold;
                color: $purple;
            }
        }
    }

    &.text-content {
        h2 {
            color: $blue;
        }
    }
}