@use "sass:map";

.keywords-grid {
    padding: 135px 0 112px 0;

    h2 {
        color: $blue;
        font-size: rem-calc(27);
        margin-bottom: 36px;
        padding-left: 2px;
    }

    &.with-intro {
        padding: 135px 0 61px 0;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 68px 20px;

        @include media('<large') {
            gap: 40px 20px;
        }

        @include media('<medium') {
            gap: 20px;
            justify-content: center;
        }
    }

    &__item {
        background-color: $white;
        width: calc(25% - 15px);
        border: 1px solid $light-purple;
        border-radius: 25px;
        color: $white;
        overflow: hidden;
        
        a {
            display: block;
        }

        &:hover {
            img {
                //transform: scale(1.1);
            }

            .image {
                .hover-text {
                    top: 0;
                }
            }
        }

        .image {
            width: 100%;
            height: 387px;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                height: inherit;
                object-fit: cover;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                transition: transform .1s ease-in;
            }

            .hover-text {
                background-color: rgba($purple, 90%);
                position: absolute;
                top: 100%;
                left: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 40px;
                padding-right: 40px;
                transition: top .2s ease-in;

                @include media('<medium') {
                    padding: 20px;
                }

                p {
                    color: $white;
                    margin-bottom: 0;
                    font-size: rem-calc(22.5);
                    display: -webkit-box;
                    -webkit-line-clamp: 7;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    @include media('<medium') {
                        font-size: rem-calc(18);
                        -webkit-line-clamp: 10;
                    }
                }
            }
        }

        .title {
            padding: 43px 40px 38px 40px;

            span {
                font-size: rem-calc(22.5);
                color: $purple;
                font-weight: bold;
                line-height: 1.33;
            }
        }

        @include media('<1280px') {
            width: calc(33.3333% - 15px);
        }

        @include media('<large') {
            width: calc(50% - 15px);
        }

        @include media('<medium') {
            width: 100%;
        }

        &.with-text {
            .image {
                height: 267px;
            }

            p {
                line-height: 1.6667;
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: $black;
            }
        }
    }
}