$button-font-family: var(--root-heading-font-family);
$button-padding: 11px 32.5px;
$button-mobile-padding: 10px 20px;
$button-font-size: rem-calc(22.5);

.btn {
  font-size: $button-font-size;
  display: inline-block;
  padding: $button-padding;
  border-radius: 50px;
  font-family: $button-font-family;
  transition: color .15s ease-in, background-color .15s ease-in;
  text-align: center;

  @include media('<large') {
    padding: $button-mobile-padding;
  }

  &.btn-primary {
    background-color: $light-purple;
    color: $purple;

    &:hover {
      background-color: $purple;
      color: $white;
    }
  }

  &.btn-secondary {
    background-color: $light-blue;
    color: $blue;

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
}
