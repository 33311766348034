body.target-kids {
    color: red;

    .btn-primary {
        background-color: rgba($red, 25%);
        color: $red;

        &:hover {
            background-color: $red;
            color: $white;
        }
    }

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
        color: $red;
        font-weight: 300;
        //font-family: var(--root-heading-font-family-kids);
    }

    p, a, li {
        color: $red;
    }

    .header__wrapper {
        background-color: $red;
    }

    .header__nav ul li:last-child .show-keywords {
        background-color: $light-red;
    }

    .keywords-popup {
        background-color: $red;
    }

    .keywords-grid__item {
        border-color: $light-blue;

        .image {
            background-color: rgba($light-blue, 50%);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: auto;
                height: auto;
                object-fit: none;
                border-radius: 0;
            }
        }

        .title span {
            color: $red;
            font-family: var(--root-heading-font-family-kids);
            font-size: rem-calc(30);
            font-weight: normal;
        }
    }

    .search-bar {
        input {
            border-color: $red;
            background: $white url("/assets/templates/images/svg/search-red.svg") no-repeat center;
            background-position-x: calc(100% - 54px);

            &::placeholder {
                color: $red;
            }

            &:-ms-input-placeholder {
                color: $red;
            }

            &::-ms-input-placeholder {
                color: $red;
            }
        }
    }

    .footer__menu nav ul li a:hover {
        color: $blue;
    }


    .slider-with-bible-text {
        background-color: $light-blue;
        margin-top: 41px;
        
        .background-image {
            display: none;
        }

        h2 {
            color: $blue;
        }

        .glide-bible-texts {
            .bible-text-block p {
                color: $red;
            }

            .glide__slide--active {

                .bible-text-block {
                    background-color: $red;

                    p {
                        color: $white;
                    }
                }
            }
        }

        .slider-icon {
            position: absolute;
            top: -58px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .text-block {
        padding-bottom: 141px;

        .content {
            padding-left: 208px;
            position: relative;

            @include media('<medium') {
                padding-left: 0;
            }

            .tab-icon {
                position: absolute;
                top: 10px;
                left: -40px;

                @include media('<medium') {
                    position: relative;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .tabs-container {
        .content {
            .tabs {
                @include media('<large') {
                    margin-bottom: 1rem;
                }

                .tabs-title {
                    border-radius: 10px 10px 0 0;
                    
                    a {
                        border-radius: 10px 10px 0 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-family: var(--root-heading-font-family-kids);
                        font-size: rem-calc(30);

                        @include media('<large') {
                            flex-direction: row;
                            padding: 8px 10px;
                            font-size: rem-calc(24);
                        }
                        
                        img {
                            width: 100px;
                            margin-bottom: 15px;

                            @include media('<large') {
                                width: 50px;
                                margin-bottom: 0;
                                margin-right: 15px;
                            }
                        }

                        &:hover {

                        }
                    }

                    &.is-active {
                        border-radius: 10px 10px 0 0;

                        a {
                            border: 2px solid $light-blue;
                            border-bottom: 0;
                            min-height: 276px;
                            margin-top: -67px;
                            justify-content: center;
                            padding: 8px 10px 0;
                            font-size: rem-calc(35);

                            @include media('<large') {
                                justify-content: flex-start;
                                align-items: center;
                                min-height: auto;
                                margin-top: 0;
                                padding: 8px 10px;
                                border-radius: 10px;
                                border-bottom: 1px solid $blue;
                                font-size: rem-calc(24);
                            }

                            img {
                                width: 130px;
                                margin-bottom: 22px;

                                @include media('<large') {
                                    width: 50px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .tabs-content .tab-bijbelrooster .day-item {
                border-color: $red;
                &__header {
                    .left .title {
                        color: $red;
                    }

                    .right button.active {
                        color: $blue;
                    }
                }

                &__read-text-inner p {
                    color: $black;
                }
            }

            .tabs-content .tab-kijk-luister .video-grid__item .meta {
                color: $red;

                .text {
                    margin-top: 30px;

                    p, li {
                        color: #7C7C7C;
                    }
                }
            }
        }
    }

    .slider-with-keywords .related-keywords__item {
        .cover-image {
            background-color: rgba($light-blue, 50%);
        }

        a {
            &:hover {
                color: $blue;
            }
        }
    }

    .contact-form {
        .content {
            padding-left: 208px;
            position: relative;

            @include media('<medium') {
                padding-left: 0;
            }

            .form-icon {
                position: absolute;
                top: -10px;
                left: -40px;

                @include media('<medium') {
                    position: relative;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .keywords-grid {
        h2 {
            color: $blue;
        }

        &__item.with-text p {
            color: $black;
        }
    }

    .pagination a {
        color: $blue;
    }

    .share-page .content {
        color: rgba($red, 50%);
    }

    .open-payment-popup {
        background: url("/assets/templates/images/svg/payment-icon-kids.svg") no-repeat;
    }

    .header-kids-with-textblock .header-content .text-block h1 {
        font-size: rem-calc(50);
        font-family: var(--root-heading-font-family-kids);
    }

    .header-top__wrapper .show-keywords {
        color: $red;
        border-color: $red;

        &:hover {
            background-color: $red;
            color: $white;
        }
    }

    .text-block .content p:first-of-type {
        color: $red;
    }

    .newsletter h2 {
        color: $blue;
    }

    [type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color], textarea, select {
        border-color: $red;
    }

    .form-group > label {
        color: $red;
    }

    .payment-popup {
        background-color: $red;

        &__content .button-container .payment-input {
            color: $light-red;
            border-color: #F9CED1;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #F9CED1;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #F9CED1;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #F9CED1;
            }
        }
    }

    .header__lang-toggle {
        color: $red;
    }

    .header__menutrigger .icon .base-line,
    .header__menutrigger .icon::before,
    .header__menutrigger .icon::after {
        background-color: $red;
    }

    .header__nav {
        @include media('<large') {
            background-color: $red;
        }
    }

    .keywords-grid__item .image .hover-text {
        background-color: rgba($red, 90%);
    }

    .video-text-button .content .text p:first-of-type {
        color: $red;
    }
}