@use "sass:map";

.header-with-search {
    height: 810px;
    position: relative;
    overflow: hidden;

    @include media('<medium') {
        height: 530px;
    }

    > .row {
        height: 100%;
    }

    .cover-image {
        object-fit: cover;
        width: 100%;
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .header-content {
        padding: 183px 0 144px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @include media('<medium') {
            padding: 100px 0;
            gap: 30px;
        }

        .word-of-the-week {
            max-width: 601px;
            border-radius: 25px;
            border: 1px solid $white;
            margin-left: auto;

            .title {
                background-color: $blue;
                color: $white;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                padding: 14px 39px;
                font-weight: bold;

                @include media('<medium') {
                    padding: 10px 20px;
                }
            }

            .text {
                color: $purple;
                padding: 28px 39px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                background-color: $white;

                @include media('<medium') {
                    padding: 20px;
                }

                h1 {
                    color: $purple;
                    font-size: rem-calc(40);
                    margin-bottom: 17px;

                    @include media('<medium') {
                        font-size: rem-calc(32);
                        margin-bottom: 10px;
                    }
                }

                p {
                    font-size: rem-calc(22.5);
                    color: $purple;
                    margin-bottom: 0;
                    padding-right: 1rem;

                    @include media('<medium') {
                        font-size: rem-calc(18);
                    }
                }
            }
        }
    }
}