@use "sass:map";

.contact-form {
    padding: 130px 0 66px 0;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        background: url("/assets/templates/images/svg/lines-blue-bg-right.svg");
        height: 600px;
        width: 250px;
        position: absolute;
        top: 169px;
        right: -7px;
        z-index: -1;

        @include media('<medium') {
           // display: none;
        }
    }

    .content {
        padding: 0 98px;

        @include media('<medium') {
            padding: 0;
        }

        h2 {
            color: $blue;
            margin-bottom: 38px;
            font-size: rem-calc(40);
        }

        p {
            line-height: 1.6667;
            color: $black;
        }

        form {
            margin-top: 38px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0 30px;
            align-items: center;
            justify-content: center;

            .form-group {
                width: 100%;

                &:nth-child(1),
                &:nth-child(2) {
                    width: calc(50% - 15px);

                    @include media('<large') {
                        width: 100%;
                    }
                }
            }

            textarea {
                min-height: 178px;
            }

            .form-pagination {
                margin-top: 48px;
            }
        }
    }
}