@use "sass:map";

.video-text-button {
    padding: 0 0 68px 0;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        background: url("/assets/templates/images/svg/lines-blue-bg-right.svg");
        height: 600px;
        width: 250px;
        position: absolute;
        bottom: 249px;
        right: -7px;
        z-index: -1;

        @include media('<large') {
            //display: none;
        }
    }

    @include media('<medium') {
        padding: 0 0 40px 0;
    }

    .content {
        padding: 64px 146px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 88px;
        margin-right: 88px;

        @include media('<1280px') {
            padding: 64px 64px;
        }

        @include media('<large') {
            margin-left: 30px;
            margin-right: 30px;
        }

        @include media('<medium') {
            margin-left: 0;
            margin-right: 0;
            padding: 30px 30px 0 30px;
        }

        &::before {
            content: "";
            width: 100%;
            height: 645px;
            background-color: $light-blue;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            @include media('<1500px') {
                height: 500px;
            }

            @include media('<1280px') {
                height: 400px;
            }

            @include media('<medium') {
                height: 350px;
            }
        }

        h2 {
            font-size: rem-calc(27);
            color: $blue;
            margin-bottom: 36px;
        }

        .video {
            margin-bottom: 133px;

            @include media('<1280px') {
                margin-bottom: 50px;
            }
        }

        .text {
            h2 {
                font-size: rem-calc(40);
            }

            p {
                color: $black;

                &:first-of-type {
                    color: $purple;
                    font-weight: bold;
                }
            }
        }

        .btn {
            margin-top: 28px;
            align-self: center;
        }

        p {
            line-height: 1.6667;
            margin-bottom: 38px;
        }
    }
}

.video {
    display: flex;
    align-items: center;
    justify-content: center;

    .play-button {
        position: absolute;
        cursor: pointer;
        transition: transform .2s ease-in;

        &:hover {
            transform: scale(1.2);
        }
    }
}