.form-group {
    margin-bottom: 1rem;

    > label {
        color: $purple;
        font-weight: bold;
        font-size: rem-calc(18);
        margin-bottom: 10px;
    }

    .form-control--wrapper {
        label {
            color: $purple;
            font-size: 1rem;
        }
    }

    .form-control--description {
        font-size: rem-calc(16);
        font-style: italic;
    }
}

.form-pagination {
    text-align: center;

    .btn {
        background-color: $light-blue;
        color: $blue;
        line-height: inherit;

        &:hover {
            background-color: $blue;
            color: $white;
        }
    }
}

[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color], textarea, select {
    border: 1px solid $purple;
    border-radius: 5px;
    box-shadow: none;
    height: 60px;
    margin-bottom: 5px;
    padding: 10px 20px;
}